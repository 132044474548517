<template>
  <div>
    <AdminSubMenu :sub-menu="subMenu" style="padding-top: 60px;">
      {{ $t('Guest Email') }}
    </AdminSubMenu>
    <router-view />
  </div>
</template>

<i18n>
{
  "en": {
    "Email templates": "Email templates",
    "Guest Email": "Guest Email"
  },
  "ja": {
    "Email templates": "Email templates",
    "Guest Email": "自動メール送信"
  }
}
</i18n>

<script>
import AdminSubMenu from '@/components/AdminSubMenu';

export default {
  name: 'GuestEmail',
  components: { AdminSubMenu },
  computed: {
    subMenu() {
      const menu = [
        {
          name: 'guest-email-templates',
          title: this.$t('Email templates'),
        },
      ];

      return menu;
    },
    childrenRoutes() {
      return [
        'guest-email-templates',
      ];
    },
  },
};
</script>
